






import Vue from 'vue';
import AppListItemAddress from '@/components/AppListItemAddress.vue';
import { ScheduledPickup } from '@/types/schema';

export default Vue.extend({
  name: 'AppListPickup',
  components: {
    AppListItemAddress,
  },
  inheritAttrs: false,
  props: {
    pickup: {
      type: Object as () => ScheduledPickup,
      default: Object as () => ScheduledPickup,
    },
  },
});
