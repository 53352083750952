



































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListPickup from '@/components/AppListPickup.vue';
import ROUTE from '@/graphql/queries/ScheduledRoute.graphql';
import { ROUTE_STATUSES } from '@/utils/constants';
import { ScheduledRoute, ScheduledRouteQueryVariables } from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'DriverRoute',
  components: {
    AppMain,
    AppPanel,
    AppPanelItem,
    AppListPickup,
  },
  data() {
    return {
      route: {} as ScheduledRoute,
    };
  },
  computed: {
    hasRoute(): boolean {
      return this.route && !!this.route.id;
    },
  },
  created() {
    if (!this.$route.params.id) this.$router.replace({ name: 'home' });
  },
  apollo: {
    route: {
      query: ROUTE,
      variables(): ScheduledRouteQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ route: ScheduledRoute }>) {
        if (!data) return;
        if (!data.route || data.route.status !== ROUTE_STATUSES.STARTED) {
          this.$router.replace({ name: 'driver-route-start' });
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  },
});
